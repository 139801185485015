<template>
  <v-card
    v-if="isAuthenticated"
    class="d-flex flex-column"
    style="height: calc(100vh - 80px); overflow: hidden"
  >
    <v-progress-linear
      :active="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-card-title v-if="!loading">
      <v-row class="w-100" no-gutters>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="filtroNombre"
            label="Buscar por Nombre"
            single-line
            hide-details
            dense
            outlined
            class="mr-2"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-select
            v-model="filtroDeviceType"
            :items="deviceTypeOptions"
            label="Tipo de dispositivo"
            item-title="title"
            item-value="value"
            clearable
            dense
            outlined
            class="mr-2"
          ></v-select>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="filtroLocation"
            label="Buscar por Localización"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="filtroReader"
            label="Buscar por Reader"
            single-line
            hide-details
            dense
            outlined
            class="mr-2"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>

    <!-- Aquí usamos el v-data-table estable con show-select -->
    <v-data-table
      id="inventory-table"
      v-if="!loading"
      :headers="headers"
      :items="datosParaTabla"
      v-model:page="page"
      v-model:items-per-page="itemsPerPage"
      class="elevation-1 flex-grow-1"
      fixed-header
      hide-default-footer
      item-value="Id"
      v-model="selectedDevices"
      show-select
      style="overflow: hidden"
    >
      <!-- Slots por columna -->
      <template #[`item.Name`]="{ item }">
        <span
          :class="itemClass(item)"
          style="display: block; width: 100%; height: 100%"
        >
          {{ item.Name }}
        </span>
      </template>

      <template #[`item.statusKey`]="{ item }">
        <span
          :class="itemClass(item)"
          style="display: block; width: 100%; height: 100%"
        >
          {{ item.statusKey }}
        </span>
      </template>

      <template #[`item.LOCATION`]="{ item }">
        <span
          :class="itemClass(item)"
          style="display: block; width: 100%; height: 100%"
        >
          {{ item.LOCATION }}
        </span>
      </template>

      <template #[`item.Reader`]="{ item }">
        <span
          :class="itemClass(item)"
          style="display: block; width: 100%; height: 100%"
        >
          {{ item.Reader }}
        </span>
      </template>

      <template #[`item.updated`]="{ item }">
        <span
          :class="itemClass(item)"
          style="display: block; width: 100%; height: 100%"
        >
          {{ item.updated }}
        </span>
      </template>

      <template #bottom>
        <!-- Vista para pantallas pequeñas: dos filas -->
        <div class="d-flex d-md-none flex-column w-100 pa-2">
          <!-- Primera fila: Selector, Paginación, Total -->
          <v-row class="w-100 d-flex align-center justify-start mb-2 flex-wrap">
            <v-col cols="12" class="mb-2">
              <v-select
                v-model="itemsPerPage"
                :items="itemsPerPageOptions"
                item-title="title"
                item-value="value"
                label="Filas por página"
                hide-details
                dense
                outlined
                style="max-width: 250px; min-width: 200px"
              ></v-select>
            </v-col>
            <v-col cols="12" class="mb-2">
              <v-pagination
                v-model="page"
                :length="pageCount"
                total-visible="3"
              ></v-pagination>
            </v-col>
            <v-col cols="12">
              <span>Total de dispositivos: {{ totalDevices }}</span>
            </v-col>
          </v-row>
          <!-- Segunda fila: Botones en dos lineas -->
          <v-row class="w-100 d-flex align-center justify-end flex-wrap">
            <v-col cols="12" class="d-flex justify-end mb-2">
              <v-btn
                color="error"
                dark
                @click="eliminarDispositivos"
                small
                aria-label="Eliminar Dispositivos"
                :disabled="selectedDevices.length === 0"
              >
                <v-icon left>mdi-delete</v-icon>
                Eliminar Dispositivos
              </v-btn>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                color="success"
                dark
                @click="exportarExcel"
                small
                aria-label="Exportar a Excel"
              >
                <v-icon left>mdi-file-excel</v-icon>
                Exportar a Excel
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <!-- Vista para pantallas grandes: todo en una sola fila -->
        <div
          class="d-none d-md-flex w-100 pa-2 align-center justify-space-between flex-wrap"
        >
          <div class="d-flex align-center flex-wrap">
            <v-select
              v-model="itemsPerPage"
              :items="itemsPerPageOptions"
              item-title="title"
              item-value="value"
              label="Filas por página"
              hide-details
              dense
              outlined
              class="mr-4"
              style="max-width: 250px; min-width: 200px"
            ></v-select>

            <v-pagination
              v-model="page"
              :length="pageCount"
              total-visible="3"
              class="mr-4"
            ></v-pagination>

            <span>Total de dispositivos: {{ totalDevices }}</span>
          </div>

          <div class="d-flex">
            <v-btn
              color="error"
              dark
              @click="eliminarDispositivos"
              class="mr-4"
              small
              aria-label="Eliminar Dispositivos"
              :disabled="selectedDevices.length === 0"
            >
              <v-icon left>mdi-delete</v-icon>
              Eliminar Dispositivos
            </v-btn>
            <v-btn
              color="success"
              dark
              @click="exportarExcel"
              small
              aria-label="Exportar a Excel"
            >
              <v-icon left>mdi-file-excel</v-icon>
              Exportar a Excel
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useDeviceStore } from "@/stores/deviceStore";
import { useAuthStore } from "@/stores/authStore";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import api from "@/services/api";

export default {
  name: "InventoryView",
  setup() {
    const deviceStore = useDeviceStore();
    deviceStore.setFiltroReader("");
    deviceStore.setFiltroDevicetype(null);
    const authStore = useAuthStore();
    const loading = ref(true);
    const isAuthenticated = ref(authStore.isAuthenticated);
    const selectedDevices = ref([]);

    const toggleSelection = (item, value) => {
      if (value && !selectedDevices.value.includes(item.Id)) {
        selectedDevices.value.push(item.Id);
      } else if (!value && selectedDevices.value.includes(item.Id)) {
        selectedDevices.value = selectedDevices.value.filter(
          (id) => id !== item.Id
        );
      }
    };

    const filtroNombre = computed({
      get: () => deviceStore.filtroNombre,
      set: (value) => {
        deviceStore.filtroNombre = value;
      },
    });

    const filtroReader = computed({
      get: () => deviceStore.filtroReader,
      set: (value) => {
        deviceStore.filtroReader = value;
      },
    });

    const filtroDeviceType = computed({
      get: () => deviceStore.filtroDeviceType,
      set: (value) => {
        deviceStore.filtroDeviceType = value;
      },
    });

    const filtroLocation = computed({
      get: () => deviceStore.filtroLocation,
      set: (value) => {
        deviceStore.filtroLocation = value;
      },
    });

    const itemsPerPage = ref(10);
    const page = ref(1);

    const deviceTypeOptions = ref([]);

    const itemsPerPageOptions = [
      { title: "10", value: 10 },
      { title: "20", value: 20 },
      { title: "50", value: 50 },
      { title: "100", value: 100 },
      { title: "500", value: 500 },
      { title: "1000", value: 1000 },
    ];

    const datosParaTabla = computed(() => {
      return deviceStore.devicesFiltrados.map((device) => ({
        ...device,
        LOCATION: device.Location || "Sin ubicación",
      }));
    });

    const itemClass = (item) => {
      if (!item.updated) return "";
      const lastUpdate = new Date(item.updated);
      const differenceInDays =
        (Date.now() - lastUpdate) / (1000 * 60 * 60 * 24);
      return differenceInDays > 3 ? "highlight-red" : "";
    };

    const allSelected = computed(() => {
      const items = datosParaTabla.value;
      return (
        items.length > 0 &&
        items.every((item) => selectedDevices.value.includes(item.Id))
      );
    });

    const toggleSelectAll = (value) => {
      if (value) {
        const allIds = datosParaTabla.value.map((item) => item.Id);
        selectedDevices.value = Array.from(
          new Set([...selectedDevices.value, ...allIds])
        );
      } else {
        selectedDevices.value = selectedDevices.value.filter((id) => {
          return !datosParaTabla.value.some((item) => item.Id === id);
        });
      }
    };

    const headers = [
      { title: "", value: "select", sortable: false },
      { title: "Nombre", value: "Name", sortable: true },
      { title: "Estado", value: "statusKey", sortable: true },
      { title: "Localización", value: "LOCATION", sortable: true },
      { title: "Reader", value: "Reader", sortable: true },
      { title: "Última Actualización", value: "updated", sortable: true },
    ];

    const pageCount = computed(() => {
      if (itemsPerPage.value === -1) {
        return 1;
      }
      return Math.ceil(datosParaTabla.value.length / itemsPerPage.value);
    });

    const totalDevices = computed(() => datosParaTabla.value.length);

    const exportarExcel = async () => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Inventario");
      worksheet.columns = headers.map((header) => ({
        header: header.title,
        key: header.value,
        width: 20,
      }));
      datosParaTabla.value.forEach((device) => {
        worksheet.addRow({
          Name: device.Name,
          statusKey: device.statusKey,
          LOCATION: device.LOCATION,
          updated: device.updated,
        });
      });
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), "Inventario.xlsx");
    };

    const eliminarDispositivos = async () => {
      if (selectedDevices.value.length === 0) {
        alert("Selecciona al menos un dispositivo para eliminar.");
        return;
      }

      try {
        const result = await api.deleteDevices(selectedDevices.value);
        const idsEliminados = result.resultados
          .filter((r) => r.status === "success")
          .map((r) => r.id);

        deviceStore.devices = deviceStore.devices.filter(
          (device) => !idsEliminados.includes(device.id)
        );

        selectedDevices.value = [];
        alert("Dispositivos eliminados correctamente");
      } catch (error) {
        if (error.status === 403) {
          alert(
            "No tienes permisos de administrador para eliminar dispositivos."
          );
        } else {
          alert(`Error al eliminar dispositivos: ${error.message}`);
        }
      }
    };

    onMounted(async () => {
      if (!deviceStore.devices.length) {
        await deviceStore.fetchDevices();
        onDataLoaded();
      }

      if (deviceStore.filtroFamily) {
        filtroNombre.value = deviceStore.filtroFamily[0];
        deviceStore.filtroFamily = [];
      }

      deviceStore.startPeriodicUpdate();

      const deviceTypes = new Set(deviceStore.devices.map((d) => d.DeviceType));
      const deviceTypeTitles = {
        54: "Reader",
        55: "Beacon/Tracker",
        64: "Smart Beacon/Tracker",
      };
      deviceTypeOptions.value = Array.from(deviceTypes)
        .filter((type) => type !== undefined && type !== null)
        .map((type) => ({
          title: deviceTypeTitles[type] || `Tipo ${type}`,
          value: type,
        }));

      loading.value = false;
    });

    const onDataLoaded = () => {
      loading.value = false;
    };

    return {
      loading,
      filtroNombre,
      filtroDeviceType,
      filtroLocation,
      deviceTypeOptions,
      itemsPerPage,
      page,
      itemsPerPageOptions,
      datosParaTabla,
      headers,
      exportarExcel,
      pageCount,
      totalDevices,
      isAuthenticated,
      filtroReader,
      eliminarDispositivos,
      selectedDevices,
      itemClass,
      toggleSelection,
      allSelected,
      toggleSelectAll,
    };
  },
};
</script>

<style>
th {
  color: black !important;
}

.pa-2 {
  padding: 0.5rem;
}

.highlight-red {
  background-color: #ffcccc !important;
  color: black !important;
}

.button-group {
  display: flex;
  justify-content: end;
  gap: 10px;
}

@media (max-width: 600px) {
  .text-right {
    text-align: center !important;
  }

  .mr-4 {
    margin-right: 0 !important;
    margin-bottom: 0.5rem !important;
  }
}

#inventory-table table {
  border-collapse: collapse !important;
}

#inventory-table td {
  padding: 0 !important;
  border: none !important;
}

#inventory-table td .highlight-red {
  background-color: #ffcccc !important;
  color: black !important;
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

/* Para colorear la fila completa usando :has() */
#inventory-table tbody tr:has(.highlight-red) td {
  background-color: #ffcccc !important;
}
</style>
