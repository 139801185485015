import { defineStore } from "pinia";
import api from "@/services/api";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    isAuthenticated: false,
    user: null,
  }),
  actions: {
    async login(username, password) {
      try {
        const { status } = await api.login(username, password);
        if (status === 200) {
          this.isAuthenticated = true;
          this.user = username;
        } else {
          alert("Usuario o contraseña incorrectos");
        }
      } catch (error) {
        console.error("Error al intentar iniciar sesión:", error);
      }
    },
    async logout() {
      try {
        const response = await api.logout();
        if (response.ok) {
          this.isAuthenticated = false;
          this.user = null;
        }
      } catch (error) {
        console.error("Error al iniciar sesión: ", error);
        throw error;
      }
    },
    // Verificar si hay un token presente (en cookies)
    async checkAuthentication() {
      try {
        const response = await api.validateToken(); // Un endpoint que verifica si el token es válido.
        if (response.status === 200) {
          this.isAuthenticated = true;
        } else {
          this.isAuthenticated = false;
        }
      } catch (error) {
        console.error("Error verificando la autenticación: ", error);
        this.isAuthenticated = false;
      }
    },
  },
  persist: {
    paths: ["isAuthenticated", "user"],
  },
});
