<template>
  <v-container v-if="isAuthenticated" fluid id="dashboard-container">
    <v-row>
      <!-- Filtro de Familia -->
      <v-col cols="12" md="6">
        <v-select
          v-model="selectedFamilies"
          :items="familyOptions"
          label="Graficar Familias"
          multiple
          clearable
          outlined
          dense
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <!-- Gráfico 1: Distribución de Contenedores por Localización (Gráfico de Donut) -->
      <v-col cols="12" md="6">
        <v-card class="pa-4">
          <v-card-title class="justify-center">
            Distribución por Localización
          </v-card-title>
          <PieChart
            :labels="locationLabels"
            :data="locationData"
            :backgroundColors="locationColors"
            :customTooltipData="locationFamilyBreakdown"
            chartTitle=""
          />
        </v-card>
      </v-col>

      <!-- Gráfico 2: Disponibilidad de Contenedores por Ubicación (Gráfico de Barras) -->
      <v-col cols="12" md="6">
        <v-card class="pa-4">
          <v-card-title class="justify-center">
            Contenedores por Ubicación
          </v-card-title>
          <BarChart
            :labels="locationLabels"
            :data="locationData"
            :backgroundColors="locationColors"
            :customTooltipData="locationFamilyBreakdown"
            chartTitle=""
            barLabel="Cantidad de Contenedores"
          />
        </v-card>
      </v-col>

      <!-- Gráfico 3: Top 5 Ubicaciones con más Contenedores -->
      <v-col cols="12" md="6">
        <v-card class="pa-4">
          <v-card-title class="justify-center">
            Top 5 Ubicaciones con más Contenedores
          </v-card-title>
          <BarChart
            :labels="top5LocationLabels"
            :data="top5LocationData"
            :backgroundColors="top5LocationColors"
            chartTitle=""
            barLabel="Cantidad de Contenedores"
          />
        </v-card>
      </v-col>

      <!-- Gráfico 4: Distribución de Contenedores por Familia -->
      <v-col cols="12" md="6">
        <v-card class="pa-4">
          <v-card-title class="justify-center">
            Distribución por Familia
          </v-card-title>
          <PieChart
            :labels="familyLabels"
            :data="familyData"
            :backgroundColors="familyColors"
            chartTitle=""
          />
        </v-card>
      </v-col>

      <!-- Nuevo Gráfico: Dispositivos Inactivos (>3 días) por Familia -->
      <v-col cols="12" md="6">
        <v-card class="pa-4">
          <v-card-title class="justify-center">
            Dispositivos Inactivos (>3 días) por Familia
          </v-card-title>
          <BarChart
            :labels="inactiveFamilyLabels"
            :data="inactiveFamilyData"
            :backgroundColors="inactiveFamilyColors"
            chartTitle=""
            barLabel="Cantidad de Dispositivos Inactivos"
          />
        </v-card>
      </v-col>

      <!-- Nuevo Gráfico: Distribución de Estados de Dispositivos -->
      <v-col cols="12" md="6">
        <v-card class="pa-4">
          <v-card-title class="justify-center">
            Distribución de Estados de Dispositivos
          </v-card-title>
          <PieChart
            :labels="statusLabels"
            :data="statusData"
            :backgroundColors="statusColors"
            chartTitle=""
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useDeviceStore } from "@/stores/deviceStore";
import { useAuthStore } from "@/stores/authStore";
import PieChart from "@/components/PieChart.vue";
import BarChart from "@/components/BarChart.vue";

export default defineComponent({
  name: "GraphicsView",
  components: {
    PieChart,
    BarChart,
  },
  setup() {
    const deviceStore = useDeviceStore();
    const authStore = useAuthStore();
    const isAuthenticated = ref(authStore.isAuthenticated);

    // Filtros
    const selectedFamilies = ref([]);

    const familyOptions = computed(() => {
      const families = Array.from(new Set(deviceStore.families));
      return families.sort((a, b) => a.localeCompare(b));
    });

    watch(selectedFamilies, () => {
      deviceStore.setFiltroFamily(selectedFamilies.value.join(", "));
    });

    // 📊 **Distribución por Localización**
    const locationLabels = computed(() =>
      Object.keys(deviceStore.devicesCountByLocationGraph)
    );
    const locationData = computed(() =>
      Object.values(deviceStore.devicesCountByLocationGraph)
    );
    const locationColors = computed(() =>
      generateColors(locationLabels.value.length)
    );

    // 📊 **Distribución de Contenedores por Familia**
    const familyLabels = computed(() =>
      Object.keys(deviceStore.devicesCountByFamily)
    );
    const familyData = computed(() =>
      Object.values(deviceStore.devicesCountByFamily)
    );
    const familyColors = computed(() =>
      generateColors(familyLabels.value.length)
    );

    // Top 5 Ubicaciones con más Contenedores
    const top5LocationData = computed(() => {
      const locationDataArray = Object.entries(
        deviceStore.devicesCountByLocationGraph
      )
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5);
      return locationDataArray.map((entry) => entry[1]);
    });

    const top5LocationLabels = computed(() => {
      const locationDataArray = Object.entries(
        deviceStore.devicesCountByLocationGraph
      )
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5);
      return locationDataArray.map((entry) => entry[0]);
    });

    const top5LocationColors = computed(() =>
      generateColors(top5LocationLabels.value.length)
    );

    // Desglose por Ubicación y Familia
    const locationFamilyBreakdown = computed(() => {
      const result = {};

      deviceStore.devices.forEach((device) => {
        const fam = (device.Name || "Sin familia").split("-")[0].trim();

        // Solo considerar dispositivos cuya familia esté seleccionada, si hay alguna seleccionada
        if (
          selectedFamilies.value.length === 0 ||
          selectedFamilies.value.includes(fam)
        ) {
          const loc = device.Location || "Desconocido";

          if (!result[loc]) {
            result[loc] = {};
          }
          if (!result[loc][fam]) {
            result[loc][fam] = 0;
          }
          result[loc][fam]++;
        }
      });

      console.log("locationFamilyBreakdown:", result); // Depuración

      return result;
    });

    // Dispositivos Inactivos por Familia
    const inactiveFamilyData = computed(() =>
      Object.values(deviceStore.inactiveDevicesCountByFamily)
    );
    const inactiveFamilyLabels = computed(() =>
      Object.keys(deviceStore.inactiveDevicesCountByFamily)
    );
    const inactiveFamilyColors = computed(() =>
      generateColors(inactiveFamilyLabels.value.length)
    );

    // Distribución de Estados de Dispositivos
    const statusLabels = computed(() =>
      Object.keys(deviceStore.devicesCountByStatus)
    );
    const statusData = computed(() =>
      Object.values(deviceStore.devicesCountByStatus)
    );
    const statusColors = computed(() =>
      generateColors(statusLabels.value.length)
    );

    onMounted(async () => {
      if (deviceStore.devices.length === 0) {
        await deviceStore.fetchDevices();
      }
    });

    watch(selectedFamilies, (newValue) => {
      deviceStore.setFiltroFamily(newValue); // Pasar array de familias seleccionadas
    });

    const generateColors = (length) => {
      const colors = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
        "#FF9F40",
        "#C9CBCF",
      ];
      return Array.from(
        { length },
        (_, index) => colors[index % colors.length]
      );
    };

    return {
      isAuthenticated,
      selectedFamilies,
      familyOptions,
      locationLabels,
      locationData,
      locationColors,
      familyLabels,
      familyData,
      familyColors,
      top5LocationLabels,
      top5LocationData,
      top5LocationColors,
      inactiveFamilyLabels,
      inactiveFamilyData,
      inactiveFamilyColors,
      statusLabels,
      statusData,
      locationFamilyBreakdown,
      statusColors,
    };
  },
});
</script>

<style scoped>
.v-card {
  min-height: 400px;
}
</style>
