<template>
  <v-container class="mt-5">
    <v-row justify="center">
      <v-col cols="12" md="8" lg="6">
        <v-card elevation="3">
          <v-card-title class="text-h5"
            >Actualizar Información de Usuario</v-card-title
          >
          <v-card-text>
            <v-form ref="form">
              <!-- Campo para nombre -->
              <v-text-field
                v-model="nombre"
                label="Nuevo Nombre"
                outlined
                dense
                required
                placeholder="Introduce tu nuevo nombre"
              ></v-text-field>

              <!-- Campo para URL de foto de perfil -->
              <v-text-field
                v-model="fotoPerfil"
                label="Foto de Perfil (URL)"
                disabled
                outlined
                dense
                placeholder="Introduce la URL de tu foto de perfil"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="actualizar" :disabled="!nombre">
              <v-icon left>mdi-check</v-icon>Actualizar
            </v-btn>
          </v-card-actions>
        </v-card>

        <!-- Mensajes de éxito o error -->
        <v-alert
          v-if="mensaje"
          :type="mensajeExito ? 'success' : 'error'"
          class="mt-4"
          dismissible
        >
          {{ mensaje }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/api";

export default {
  name: "ProfileUpdate",
  data() {
    return {
      nombre: "",
      fotoPerfil: "",
      mensaje: null,
      mensajeExito: false, // Para determinar si el mensaje es de éxito o error
    };
  },
  methods: {
    async actualizar() {
      try {
        const message = await api.actualizarUsuario({
          nombre: this.nombre,
          foto_perfil: this.fotoPerfil || undefined,
        });
        this.mensaje = message;
        this.mensajeExito = true; // Mensaje de éxito
      } catch (error) {
        this.mensaje = "Hubo un problema al actualizar los datos.";
        this.mensajeExito = false; // Mensaje de error
      }
    },
  },
};
</script>

<style scoped>
.v-container {
  max-width: 1200px;
}
</style>
