// stores/tableStore.js

import { defineStore } from "pinia";
import api from "@/services/api";

export const useTableStore = defineStore("tableStore", {
  state: () => ({
    data: [],
    loading: false,
    error: null,
    total: 0,
    actualPage: 1, // Página real seleccionada por el usuario
    perPage: 10,
    totalPages: 1,
  }),
  getters: {
    // Siempre devolver 1 para la página de la tabla
    tablePage: () => 1,
  },
  actions: {
    /**
     * Fetches table data with pagination based on actualPage.
     * @param {number} page - The current page number selected by the user.
     * @param {number} perPage - Number of items per page.
     */
    async fetchTableData(page = this.actualPage, perPage = this.perPage) {
      this.loading = true;
      this.error = null;
      try {
        const response = await api.getTableData(page, perPage);

        // Procesar los datos para expandir "Valor"
        const processedData = response.data.map((row) => {
          if (row.Valor) {
            try {
              const valorObj = JSON.parse(row.Valor);
              delete row.Valor;
              delete row.Variable;
              return { ...row, ...valorObj };
            } catch (error) {
              console.warn(
                `Error al parsear "Valor" para Dispositivo: ${row.Dispositivo}`,
                error
              );
              return row;
            }
          }
          return row;
        });

        this.data = processedData;
        this.total = response.total;
        this.actualPage = response.page;
        this.perPage = response.per_page;
        this.totalPages = response.total_pages;
      } catch (err) {
        this.error = err.message || "Error al obtener los datos";
      } finally {
        this.loading = false;
      }
    },
    /**
     * Actualiza la página real y solicita los datos correspondientes.
     * @param {number} pagina - La nueva página seleccionada.
     */
    setActualPage(pagina) {
      this.actualPage = pagina;
      this.fetchTableData(pagina, this.perPage);
    },
    /**
     * Actualiza el número de registros por página y reinicia a la página 1.
     * @param {number} limit - Nuevo número de registros por página.
     */
    setRegistrosPorPagina(limit) {
      this.perPage = limit;
      this.actualPage = 1; // Reinicia a la primera página
      this.fetchTableData(1, limit);
    },
  },
});
