<template>
  <v-app-bar app color="primary" dark>
    <!-- Título de la aplicación -->
    <v-toolbar-title class="title">Axia Monitoring</v-toolbar-title>

    <!-- Espaciador para empujar elementos a la derecha -->
    <v-spacer></v-spacer>

    <!-- Enlaces de navegación para pantallas grandes -->
    <v-toolbar-items class="d-none d-lg-flex">
      <v-btn text to="/"><v-icon left>mdi-map-marker</v-icon> Mapa </v-btn>
      <v-btn text to="/inventory"
        ><v-icon left>mdi-database</v-icon> Inventario</v-btn
      >
      <v-btn text to="/status"
        ><v-icon left>mdi-database-marker</v-icon> Estados</v-btn
      >
      <v-btn text to="/graphics"
        ><v-icon left>mdi-chart-box</v-icon> Gráficos</v-btn
      >
      <v-btn text to="/special"
        ><v-icon left>mdi-map-search</v-icon> Planos</v-btn
      >
      <v-btn text to="/Logistics"
        ><v-icon left>mdi-table</v-icon> Tabla MF</v-btn
      >
      <v-menu offset-y>
        <template #activator="{ props }">
          <v-btn text v-bind="props">
            <v-icon left>mdi-folder</v-icon> POC
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/NewData">
            <v-list-item-title
              ><v-icon left>mdi-table-large</v-icon>Eventos</v-list-item-title
            >
          </v-list-item>
          <v-list-item to="/poc-status">
            <v-list-item-title
              ><v-icon left>mdi-list-status</v-icon>Estado</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>

    <!-- Menú desplegable para pantallas pequeñas -->
    <v-menu offset-y class="d-lg-none">
      <template #activator="{ props }">
        <v-btn icon v-bind="props" class="d-lg-none">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item to="/">
          <v-list-item-icon><v-icon>mdi-map-marker</v-icon></v-list-item-icon>
          <v-list-item-title>Mapa</v-list-item-title>
        </v-list-item>
        <v-list-item to="/inventory">
          <v-list-item-icon><v-icon>mdi-database</v-icon></v-list-item-icon>
          <v-list-item-title>Inventario</v-list-item-title>
        </v-list-item>
        <v-list-item to="/status">
          <v-list-item-icon
            ><v-icon>mdi-database-marker</v-icon></v-list-item-icon
          >
          <v-list-item-title>Estados</v-list-item-title>
        </v-list-item>
        <v-list-item to="/graphics">
          <v-list-item-icon><v-icon>mdi-chart-box</v-icon></v-list-item-icon>
          <v-list-item-title>Gráficos</v-list-item-title>
        </v-list-item>
        <v-list-item to="/special">
          <v-list-item-icon><v-icon>mdi-map-search</v-icon></v-list-item-icon>
          <v-list-item-title>Planos</v-list-item-title>
        </v-list-item>
        <v-list-item to="/Logistics">
          <v-list-item-icon><v-icon>mdi-table</v-icon></v-list-item-icon>
          <v-list-item-title>Tabla MF</v-list-item-title>
        </v-list-item>
        <v-menu offset-y>
          <template #activator="{ props }">
            <v-btn text v-bind="props">
              <v-icon left>mdi-folder</v-icon> POC
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/NewData">
              <v-list-item-icon
                ><v-icon>mdi-table-large</v-icon></v-list-item-icon
              >
              <v-list-item-title>Eventos</v-list-item-title>
            </v-list-item>
            <v-list-item to="/poc-status">
              <v-list-item-icon
                ><v-icon>mdi-list-status</v-icon></v-list-item-icon
              >
              <v-list-item-title>Estado</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list>
    </v-menu>

    <!-- Menú del usuario -->
    <v-menu v-if="authStore.isAuthenticated" offset-y>
      <template #activator="{ props }">
        <v-btn icon v-bind="props">
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ authStore.user }}</v-list-item-title>
            <v-list-item-subtitle>Bienvenido/a</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="navigate('/Manuales')">
          <v-list-item-title>
            <v-icon>mdi-book-open-page-variant</v-icon>Manuales
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="navigate('/perfil')">
          <v-list-item-title>
            <v-icon>mdi-account-edit</v-icon>Perfil
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="handleLogout">
          <v-list-item-title>
            <v-icon>mdi-logout</v-icon>Logout
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { useAuthStore } from "@/stores/authStore";
import router from "@/router";

export default {
  name: "NavBar",
  setup() {
    const authStore = useAuthStore();

    const handleLogout = () => {
      authStore.logout();
      router.push({ name: "Login" });
    };

    const navigate = (route) => {
      router.push(route);
    };

    return {
      authStore,
      handleLogout,
      navigate,
    };
  },
};
</script>

<style scoped>
.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
}

.d-lg-none {
  display: none;
}

@media (max-width: 1280px) {
  .d-lg-none {
    display: inline-flex;
  }
  .d-lg-flex {
    display: none;
  }
}
</style>
