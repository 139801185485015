// src/stores/deviceStore.js
import { defineStore } from "pinia";
import api from "@/services/api";

export const useDeviceStore = defineStore("deviceStore", {
  state: () => ({
    devices: [],
    filtroNombre: "",
    filtroFamily: [],
    filtroDeviceType: null,
    filtroLocation: "",
    filtroreader: "",
    families: [],
    locations: [],
    devicesByFamilyAndLocation: {},
  }),
  getters: {
    devicesFiltrados(state) {
      let start = performance.now(); // Start timing the getter
      let devices = state.devices;
      if (state.filtroNombre) {
        devices = devices.filter((device) =>
          device.Name?.toLowerCase().includes(state.filtroNombre.toLowerCase())
        );
      }
      if (state.filtroDeviceType) {
        devices = devices.filter(
          (device) =>
            String(device.DeviceType) == String(state.filtroDeviceType)
        );
      }
      if (state.filtroLocation) {
        devices = devices.filter((device) =>
          device.Location?.toLowerCase().includes(
            state.filtroLocation.toLowerCase()
          )
        );
      }
      if (state.filtroReader) {
        devices = devices.filter((device) =>
          device.Reader?.toLowerCase().includes(
            state.filtroReader.toLowerCase()
          )
        );
      }
      // Filtrar por múltiples familias
      if (state.filtroFamily.length > 0) {
        devices = devices.filter((device) => {
          const family = (device.Name || "").split("-")[0].trim();
          return state.filtroFamily.includes(family); // Si la familia está en el array, incluir
        });
      }
      let end = performance.now(); // End timing
      console.log(
        `devicesFiltrados Getter took ${(end - start).toFixed(2)} ms`
      );
      return devices;
    },
    // Obtener las familias disponibles
    availableFamilies(state) {
      return state.families;
    },
    // Contar dispositivos por ubicación para la familia seleccionada
    devicesCountByLocation(state) {
      const countMap = {};
      if (state.filtroFamily.length > 0) {
        state.devices.forEach((device) => {
          const name = device.Name || "";
          const family = name.split("-")[0].trim();

          // Verificar si el dispositivo pertenece a alguna de las familias seleccionadas
          if (state.filtroFamily.includes(family)) {
            const location = device.Location || "Sin ubicación";
            const reader = device.Reader || "Sin ubicación";
            // Si la ubicación ya existe en el mapa, agregar el lector si no está presente
            if (!countMap[location]) {
              countMap[location] = {
                count: 0,
                reader: reader,
              };
            }
            countMap[location].count += 1;
          }
        });
      }
      return countMap;
    },
    devicesCountByFamily() {
      const countMap = {};
      this.devicesFiltrados.forEach((device) => {
        const name = device.Name || "";
        const family = name.split("-")[0].trim();
        if (family) {
          countMap[family] = (countMap[family] || 0) + 1;
        }
      });
      return countMap;
    },
    devicesCountByLocationGraph() {
      const countMap = {};
      this.devicesFiltrados.forEach((device) => {
        const location = device.Location || "Sin ubicación";
        countMap[location] = (countMap[location] || 0) + 1;
      });
      return countMap;
    },
    inactiveDevicesCountByFamily(state) {
      const countMap = {};
      const threeDaysAgo = new Date();
      threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

      state.devicesFiltrados.forEach((device) => {
        if (!device.updated) return; // Ignorar dispositivos sin fecha de actividad
        const updated = new Date(device.updated);
        if (isNaN(updated)) return; // Ignorar fechas inválidas

        const name = device.Name || "Sin Nombre";
        const family = name.split("-")[0].trim();

        if (updated < threeDaysAgo && state.filtroFamily.includes(family)) {
          countMap[family] = (countMap[family] || 0) + 1;
        }
      });

      return countMap;
    },
    devicesCountByStatus(state) {
      const countMap = {};
      state.devicesFiltrados.forEach((device) => {
        const name = device.Name || "";
        const family = name.split("-")[0].trim();
        if (
          state.filtroFamily.length === 0 ||
          state.filtroFamily.includes(family)
        ) {
          const status = device.statusKey || "Desconocido";
          countMap[status] = (countMap[status] || 0) + 1;
        }
      });
      return countMap;
    },
    activityTrend(state) {
      const trendMap = {};
      state.devicesFiltrados.forEach((device) => {
        const date = new Date(device.lastActive).toISOString().split("T")[0];
        if (!trendMap[date]) {
          trendMap[date] = { active: 0, inactive: 0 };
        }
        const daysInactive =
          (Date.now() - new Date(device.lastActive)) / (1000 * 60 * 60 * 24);
        if (daysInactive > 3) {
          trendMap[date].inactive += 1;
        } else {
          trendMap[date].active += 1;
        }
      });

      // Ordenar las fechas
      const sortedDates = Object.keys(trendMap).sort(
        (a, b) => new Date(a) - new Date(b)
      );
      return sortedDates.map((date) => ({
        date,
        active: trendMap[date].active,
        inactive: trendMap[date].inactive,
      }));
    },
  },
  actions: {
    async fetchDevices() {
      try {
        let start = performance.now(); // Start timing the action
        const devices = await api.getAllDevices();
        // Aplanar la estructura y añadir el campo 'Location'
        this.devices = devices.map((device) => ({
          ...device,
          Location: device.fields?.LOCATION || "Sin ubicación",
        }));
        // Procesar los dispositivos para obtener familias y ubicaciones
        let end = performance.now(); // End timing
        this.processFamilies();
        console.log(`fetchDevices Action took ${(end - start).toFixed(2)} ms`);
      } catch (error) {
        console.error("Error al obtener dispositivos:", error);
      }
    },
    processFamilies() {
      const familySet = new Set();
      this.devices.forEach((device) => {
        if (device.DeviceType == 55 || device.DeviceType == 64) {
          const name = device.Name || "";
          const family = name.split("-")[0].trim();
          if (family && !family.startsWith("08EFA")) {
            familySet.add(family);
          }
        }
      });

      this.families = Array.from(familySet);
    },
    startPeriodicUpdate() {
      // Si ya existe un intervalo, no crear uno nuevo
      if (this.updateInterval) {
        return;
      }

      // Iniciar el intervalo para actualizar los datos cada 60 segundos
      this.updateInterval = setInterval(async () => {
        console.log("Actualizando datos de dispositivos en segundo plano...");
        await this.fetchDevices();
      }, 60000);
    },
    stopPeriodicUpdate() {
      // Limpiar el intervalo si está definido
      if (this.updateInterval) {
        clearInterval(this.updateInterval);
        this.updateInterval = null;
      }
    },
    setFiltroFamily(families) {
      this.filtroFamily = families; // Asignar array de familias seleccionadas
    },
    setFiltroReader(nuevoFiltro) {
      this.filtroReader = nuevoFiltro;
    },
    setFiltroLocation(nuevoFiltro) {
      this.filtroLocation = nuevoFiltro;
    },
    setFiltroDevicetype(nuevoFiltro) {
      this.filtroDeviceType = nuevoFiltro;
    },
    setFiltroName(nuevoFiltro) {
      this.filtroNombre = nuevoFiltro;
    },
  },
});
