<template>
  <div>
    <!-- Contenido principal -->
    <div
      style="
        background-color: black;
        padding: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      "
    >
      <img
        src="../assets/imagen.png"
        alt="AidTec Solutions Logo"
        style="width: 80px; height: auto; max-height: 80px"
      />
      <h1 style="color: white; margin-left: 20px; flex: 1">Panel de Mando</h1>

      <!-- Tabs -->
      <div class="tabs" style="display: flex; gap: 15px; margin-left: auto">
        <div
          v-for="tab in tabs"
          :key="tab.name"
          class="tab"
          :class="{ active: currentTab === tab.name }"
          @click="currentTab = tab.name"
          :style="tabStyle(tab.name === currentTab)"
        >
          {{ tab.label }}
        </div>
      </div>
    </div>

    <!-- Contenido de las pestañas -->
    <!-- LISTA DE DISPOSITIVOS -->
    <div v-show="currentTab === 'list'" class="tab-content">
      <div class="filters">
        <select v-model="filters.deviceType">
          <option value="all">Todos los Tipos</option>
          <option
            v-for="(type, idx) in deviceTypes"
            :key="idx"
            :value="type.nombre"
          >
            {{ type.nombre }}
          </option>
        </select>
        <select v-model="filters.release">
          <option value="all">Liberado (Todos)</option>
          <option value="TRUE">TRUE</option>
          <option value="FALSE">FALSE</option>
        </select>
        <input
          type="text"
          v-model="filters.serialSearch"
          placeholder="Buscar por Número de Serie"
        />
        <button @click="applyFilters">Filtrar</button>
      </div>

      <div v-if="loadingDevices" class="loading">Loading...</div>
      <table v-else id="deviceTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>MAC Address</th>
            <th>Apodo</th>
            <th>Serial Number</th>
            <th>HW Version</th>
            <th>FW Version</th>
            <th>Device Type</th>
            <th>Released</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(device, index) in filteredDevices" :key="device.id">
            <td>{{ device.id }}</td>
            <td>{{ device.mac_dispositivo }}</td>
            <td>{{ device.apodo || "N/A" }}</td>
            <td>{{ device.num_serie }}</td>
            <td>{{ device.hw_version }}</td>
            <td>{{ device.fw_version }}</td>
            <td>{{ device.tipo_dispositivo }}</td>
            <td>
              <select
                v-model="device.liberado"
                @change="confirmReleaseChange(device, index)"
              >
                <option value="TRUE">TRUE</option>
                <option value="FALSE">FALSE</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- AÑADIR DISPOSITIVO -->
    <div v-show="currentTab === 'add'" class="tab-content">
      <div class="form-container">
        <form @submit.prevent="addDevice">
          <div class="form-group">
            <label for="id">ID:</label>
            <input type="text" v-model="deviceForm.id" required />
          </div>
          <div class="form-group">
            <label for="mac_dispositivo">MAC Dispositivo:</label>
            <input type="text" v-model="deviceForm.mac_dispositivo" required />
          </div>
          <div class="form-group">
            <label for="mac_serie">MAC Ficticia:</label>
            <input
              type="text"
              v-model="deviceForm.mac_serie"
              @input="updateNumSerie"
              required
            />
          </div>
          <div class="form-group">
            <label for="num_serie">Número de Serie:</label>
            <input type="text" v-model="deviceForm.num_serie" required />
          </div>
          <div class="form-group">
            <label for="hw_version">HW Version:</label>
            <input
              type="text"
              v-model="deviceForm.hw_version"
              @input="updateNumSerie"
              required
            />
          </div>
          <div class="form-group">
            <label for="fw_version">FW Version:</label>
            <input
              type="text"
              v-model="deviceForm.fw_version"
              @input="updateNumSerie"
              required
            />
          </div>
          <div class="form-group">
            <label for="tipo_dispositivo">Tipo de Dispositivo:</label>
            <select
              v-model="deviceForm.tipo_dispositivo"
              @change="generateDeviceData"
              required
            >
              <option
                v-for="(type, idx) in deviceTypes"
                :key="idx"
                :value="type.nombre"
              >
                {{ type.nombre }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="liberado">Liberado:</label>
            <select v-model="deviceForm.liberado" required>
              <option value="TRUE">TRUE</option>
              <option value="FALSE">FALSE</option>
            </select>
          </div>
          <!-- Apodo y estado en la misma acción -->
          <div class="form-group">
            <label for="apodo">Apodo:</label>
            <input type="text" v-model="clientForm.apodo" />
          </div>
          <div class="form-group">
            <label for="estado">Activado en cliente:</label>
            <select v-model="clientForm.estado">
              <option value="Activado">Activado</option>
              <option value="Desactivado">Desactivado</option>
            </select>
          </div>
          <button type="submit">Añadir y Actualizar Dispositivo</button>
        </form>
        <div
          id="addMessage"
          style="
            text-align: center;
            margin-top: 15px;
            color: #4caf50;
            font-weight: bold;
          "
        >
          {{ addMessage }}
        </div>
      </div>
    </div>

    <!-- EVENTOS -->
    <div v-show="currentTab === 'events'" class="tab-content">
      <div v-if="loadingEvents" class="loading">Cargando eventos...</div>
      <table v-else id="eventTable">
        <thead>
          <tr>
            <th>Dispositivo</th>
            <th>Apodo Dispositivo</th>
            <th>Lector</th>
            <th>Apodo Lector</th>
            <th>Última detección</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(event, idx) in events" :key="idx">
            <td>{{ event.dispositivo }}</td>
            <td>{{ event.dispositivo_apodo || "Sin Apodo" }}</td>
            <td>{{ event.lector }}</td>
            <td>{{ event.lector_apodo || "Sin Apodo" }}</td>
            <td>{{ formatEventTime(event.ultima_deteccion) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- DISPOSITIVOS CLIENTES / RELEASE -->
    <div v-show="currentTab === 'release'" class="tab-content">
      <div class="form-container">
        <div class="form-group" style="display: flex; align-items: center">
          <label style="flex: 1; margin-right: 10px; font-weight: bold"
            >MAC Cliente:</label
          >
          <input
            type="text"
            v-model="clientForm.mac_serie"
            placeholder="Introduce la MAC ficticia"
            style="
              flex: 2;
              padding: 8px;
              border: 1px solid #ddd;
              border-radius: 4px;
            "
          />
          <button
            style="
              margin-left: 10px;
              padding: 8px 12px;
              background-color: #007bff;
              color: white;
              border: none;
              border-radius: 4px;
            "
            @click="startQrScanner"
          >
            Escanear QR
          </button>
        </div>

        <!-- Contenedor QR -->
        <div
          id="qr-reader"
          v-show="showQrReader"
          style="width: 100%; margin-bottom: 15px"
        ></div>
        <div
          id="qr-reader-results"
          style="text-align: center; margin-bottom: 15px"
        >
          {{ qrResult }}
        </div>

        <form @submit.prevent="updateDeviceDetails">
          <div class="form-group">
            <label for="apodo_cliente">Apodo:</label>
            <input type="text" v-model="clientForm.apodo" />
          </div>
          <div class="form-group">
            <label for="tipo_dispositivo_cliente">Tipo de Dispositivo:</label>
            <select v-model="clientForm.tipo_dispositivo" required>
              <option
                v-for="(type, idx) in deviceTypes"
                :key="idx"
                :value="type.nombre"
              >
                {{ type.nombre }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="estado_cliente">Activado en cliente:</label>
            <select v-model="clientForm.estado">
              <option value="Activado">Activado</option>
              <option value="Desactivado">Desactivado</option>
            </select>
          </div>
          <button type="submit" style="width: 100%">
            Añadir y Actualizar Dispositivo
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Ejemplo con la librería html5-qrcode (instalada o por CDN).
 * npm install html5-qrcode --save
 */
import { Html5Qrcode } from "html5-qrcode";

export default {
  name: "PanelDispositivos",
  data() {
    return {
      /* Pestañas */
      currentTab: "list",
      tabs: [
        { name: "list", label: "Lista Dispositivos" },
        { name: "add", label: "Añadir Dispositivo" },
        { name: "release", label: "Dispositvos clientes" },
        { name: "events", label: "Eventos" },
      ],

      /* Dispositivos */
      devices: [],
      loadingDevices: false,
      deviceTypes: [],

      /* Filtro */
      filters: {
        deviceType: "all",
        release: "all",
        serialSearch: "",
      },

      /* Form para añadir o actualizar un dispositivo */
      deviceForm: {
        id: "",
        mac_dispositivo: "",
        mac_serie: "",
        num_serie: "",
        hw_version: "",
        fw_version: "",
        tipo_dispositivo: "",
        liberado: "FALSE",
      },
      addMessage: "",

      /* Eventos */
      events: [],
      loadingEvents: false,

      /* Datos adicionales para cliente (apodo, estado...) */
      clientForm: {
        mac_serie: "",
        tipo_dispositivo: "",
        apodo: "",
        estado: "Activado",
      },

      /* QR Reader */
      showQrReader: false,
      qrResult: "",
    };
  },
  computed: {
    filteredDevices() {
      // Aplica filtros a la lista de dispositivos
      const { deviceType, release, serialSearch } = this.filters;
      return this.devices.filter((d) => {
        const matchesType =
          deviceType === "all" || d.tipo_dispositivo === deviceType;
        const matchesRelease = release === "all" || d.liberado === release;
        const matchesSerial =
          !serialSearch ||
          (d.num_serie || "")
            .toLowerCase()
            .includes(serialSearch.toLowerCase());
        return matchesType && matchesRelease && matchesSerial;
      });
    },
  },
  methods: {
    /* Estilo de pestaña (por si quieres animaciones/colores) */
    tabStyle(isActive) {
      return {
        backgroundColor: isActive ? "#00cc99" : "white",
        color: isActive ? "white" : "black",
        padding: "10px 20px",
        borderRadius: "25px",
        cursor: "pointer",
        fontWeight: "bold",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        transform: isActive ? "scale(1.1)" : "none",
        border: isActive ? "2px solid #007f5f" : "none",
        marginTop: "10px",
      };
    },

    /* --------------------
     *   Dispositivos
     * -------------------- */
    async fetchDevices() {
      this.loadingDevices = true;
      try {
        const response = await fetch(
          "https://80i5rch2n3.execute-api.eu-west-1.amazonaws.com/v03/devices?action=get_devices"
        );
        const data = await response.json();
        this.devices = data;
      } catch (error) {
        console.error("Error fetchDevices:", error);
      } finally {
        this.loadingDevices = false;
      }
    },
    async fetchDeviceTypes() {
      try {
        const response = await fetch(
          "https://80i5rch2n3.execute-api.eu-west-1.amazonaws.com/v03/devices?action=get_device_types"
        );
        const data = await response.json();
        this.deviceTypes = data;
      } catch (error) {
        console.error("Error fetchDeviceTypes:", error);
      }
    },

    /* Generar ID y MACserie automáticamente si lo deseas */
    async generateDeviceData() {
      // Similar a tu lógica previa
      try {
        const tipo = this.deviceForm.tipo_dispositivo;
        if (!tipo) return;

        // Filtra dispositivos del mismo tipo
        const sameType = this.devices.filter(
          (d) => d.tipo_dispositivo === tipo
        );
        // Última mac_serie
        const lastMacSerie = sameType
          .map((d) => d.mac_serie)
          .filter(Boolean)
          .sort()
          .pop();
        if (lastMacSerie) {
          const match = lastMacSerie.match(/\d+$/);
          const lastSuffix = match ? parseInt(match[0], 10) : 0;
          const newSuffix = String(lastSuffix + 1).padStart(
            match ? match[0].length : 6,
            "0"
          );
          this.deviceForm.mac_serie = tipo + newSuffix;
        } else {
          // Si no hay ninguno, asignar un sufijo
          this.deviceForm.mac_serie = tipo + "000001";
        }

        // Generar nuevo ID basado en el max ID
        const maxId = Math.max(
          0,
          ...this.devices.map((d) => parseInt(d.id, 10) || 0)
        );
        const newId = String(maxId + 1).padStart(5, "0");
        this.deviceForm.id = newId;

        // Actualiza numSerie
        this.updateNumSerie();
      } catch (error) {
        console.error("Error generateDeviceData:", error);
      }
    },

    updateNumSerie() {
      const mac = this.deviceForm.mac_serie || "";
      const hw = this.deviceForm.hw_version || "0";
      const fw = this.deviceForm.fw_version || "0";
      this.deviceForm.num_serie = `${mac}_${hw}_${fw}`;
    },

    applyFilters() {
      // Se reactualiza la tabla dinámicamente con filteredDevices
      console.log("Aplicando filtros...");
    },

    async addDevice() {
      // Primero, actualizamos los detalles (apodo, estado) en DispositivosCliente
      await this.updateDeviceDetails();

      // Luego, registramos/actualizamos en RegistroDispositivos
      try {
        const payload = { ...this.deviceForm };
        const response = await fetch(
          "https://80i5rch2n3.execute-api.eu-west-1.amazonaws.com/v03/devices?action=register_device",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
          }
        );
        if (!response.ok) {
          throw new Error("Error en register_device: " + response.statusText);
        }
        const result = await response.json();
        console.log("Dispositivo añadido/actualizado:", result);
        this.addMessage = "Dispositivo añadido correctamente.";

        // Recargamos la lista
        await this.fetchDevices();
        // Limpia el formulario
        this.resetDeviceForm();
      } catch (error) {
        console.error("Error addDevice:", error);
        this.addMessage = "Error al añadir el dispositivo. Intenta nuevamente.";
      }
    },

    resetDeviceForm() {
      this.deviceForm = {
        id: "",
        mac_dispositivo: "",
        mac_serie: "",
        num_serie: "",
        hw_version: "",
        fw_version: "",
        tipo_dispositivo: "",
        liberado: "FALSE",
      };
      // También podrías limpiar clientForm si quieres
      // this.clientForm = { mac_serie: "", ...}
    },

    confirmReleaseChange(device, index) {
      // Confirmar el cambio
      if (
        !confirm(
          `¿Cambiar el estado del dispositivo ${device.id} a ${device.liberado}?`
        )
      ) {
        // Revertir
        this.devices[index].liberado =
          device.liberado === "TRUE" ? "FALSE" : "TRUE";
        return;
      }
      this.updateDeviceState(device.id, device.liberado);
    },

    async updateDeviceState(deviceId, newState) {
      try {
        const payload = { id: deviceId, liberado: newState };
        const response = await fetch(
          "https://80i5rch2n3.execute-api.eu-west-1.amazonaws.com/v03/devices?action=update_device_state",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
          }
        );
        if (!response.ok) throw new Error("Error HTTP: " + response.status);
        const data = await response.json();
        if (data.status !== "success") throw new Error(data.message);
        alert(data.message);
      } catch (error) {
        console.error(error);
        alert("No se pudo actualizar el estado. Intenta nuevamente.");
      }
    },

    /* --------------------
     *   Eventos
     * -------------------- */
    async fetchEvents() {
      this.loadingEvents = true;
      try {
        const response = await fetch(
          "https://80i5rch2n3.execute-api.eu-west-1.amazonaws.com/v03/devices?action=get_eventos"
        );
        this.events = await response.json();
      } catch (error) {
        console.error("Error fetchEvents:", error);
      } finally {
        this.loadingEvents = false;
      }
    },
    formatEventTime(utcString) {
      if (!utcString) return "";
      const date = new Date(utcString);
      // Ajustar manualmente si hace falta (+1 hora)
      date.setHours(date.getHours() + 1);
      return date.toLocaleString("es-ES");
    },

    /* --------------------
     *   DispositivoCliente (apodo/estado)
     * -------------------- */
    async updateDeviceDetails() {
      // Actualiza apodo y estado en DispositivosCliente
      const macSerie = this.clientForm.mac_serie || this.deviceForm.mac_serie;
      if (!macSerie) return; // si no hay MAC, salimos
      const payload = {
        mac_serie: macSerie,
        tipo_dispositivo:
          this.clientForm.tipo_dispositivo || this.deviceForm.tipo_dispositivo,
        apodo: this.clientForm.apodo,
        estado: this.clientForm.estado,
      };
      try {
        const response = await fetch(
          "https://80i5rch2n3.execute-api.eu-west-1.amazonaws.com/v03/devices?action=update_device_details",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
          }
        );
        if (!response.ok) {
          throw new Error(
            `Error al actualizar detalles: ${response.statusText}`
          );
        }
        const result = await response.json();
        console.log("Detalles dispositivo cliente actualizados:", result);
      } catch (error) {
        console.error("Error updateDeviceDetails:", error);
      }
    },

    /* --------------------
     *   QR Reader
     * -------------------- */
    startQrScanner() {
      this.showQrReader = true;
      this.qrResult = "";
      const html5QrCode = new Html5Qrcode("qr-reader");
      html5QrCode
        .start(
          { facingMode: "environment" },
          { fps: 10, qrbox: { width: 250, height: 250 } },
          (decodedText) => {
            console.log("QR escaneado:", decodedText);
            this.clientForm.mac_serie = decodedText;
            this.qrResult = "MAC Cliente detectada: " + decodedText;
            html5QrCode.stop();
            this.showQrReader = false;
          },
          (errorMessage) => {
            console.log("QR Error:", errorMessage);
          }
        )
        .catch((err) => {
          console.error("No se pudo iniciar el lector QR:", err);
          alert("Revisa permisos de cámara y consola.");
        });
    },
  },
  async mounted() {
    // Cargar datos iniciales
    await this.fetchDeviceTypes();
    await this.fetchDevices();
    // Podrías cargar los eventos aquí si quieres que aparezcan de inmediato
    // await this.fetchEvents();
  },
};
</script>

<style scoped>
.loading {
  text-align: center;
  font-size: 1.5em;
  color: #555;
}
.form-container {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}
.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}
.form-group input,
.form-group select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
/* Tabs y estilos extra */
.tab-content {
  border: 1px solid #ddd;
  padding: 20px;
  background-color: white;
  margin-top: -1px;
}
.tab.active {
  background-color: #00cc99 !important;
  color: white !important;
  border: 2px solid #007f5f !important;
  transform: scale(1.1) !important;
}
</style>
