import { defineStore } from "pinia";
import api from "@/services/api";

export const usePocStore = defineStore("pocStore", {
  state: () => ({
    data: [],
    loading: false,
    error: null,
  }),
  actions: {
    async fetchPocData() {
      this.loading = true;
      this.error = null;
      try {
        this.data = await api.getPocStatus();
      } catch (error) {
        this.error = error.message;
        console.error("Error al obtener datos del POC:", error);
      } finally {
        this.loading = false;
      }
    },
  },
});
