<template>
  <v-container v-if="isAuthenticated" fluid>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>

    <v-alert v-if="error" type="error" dismissible>{{ error }}</v-alert>

    <v-data-table
      v-else
      :headers="headers"
      :items="pocData"
      class="elevation-1"
    >
      <template v-slot:[`item.TieneBateria`]="{ item }">
        <v-icon :color="item.TieneBateria ? 'green' : 'red'">
          {{ item.TieneBateria ? "mdi-check-circle" : "mdi-alert-circle" }}
        </v-icon>
      </template>
      <template v-slot:[`item.Roll`]="{ item }">
        <span>{{
          item.Roll !== null ? item.Roll.toFixed(2) + "°" : "Sin datos"
        }}</span>
      </template>
      <template v-slot:[`item.Pitch`]="{ item }">
        <span>{{
          item.Pitch !== null ? item.Pitch.toFixed(2) + "°" : "Sin datos"
        }}</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { usePocStore } from "@/stores/pocStore";

const authStore = useAuthStore();
const pocStore = usePocStore();

const isAuthenticated = ref(authStore.isAuthenticated);
const headers = [
  { title: "Dispositivo", value: "Dispositivo", sortable: true },
  { title: "Último Registro", value: "UltimoRegistro", sortable: true },
  { title: "Estado Batería", value: "TieneBateria", sortable: true },
  { title: "Roll", value: "Roll", sortable: true },
  { title: "Pitch", value: "Pitch", sortable: true },
];

const pocData = computed(() => pocStore.data);
const loading = computed(() => pocStore.loading);
const error = computed(() => pocStore.error);

onMounted(() => {
  if (isAuthenticated.value) {
    pocStore.fetchPocData();
  }
});
</script>
