<!-- src/components/RealTimeTable.vue -->
<template>
  <v-card style="height: calc(100vh - 100px); overflow-y: auto">
    <v-data-table
      :headers="displayHeaders"
      :items="extendedTableData"
      :loading="isLoading"
      class="elevation-1 custom-table"
      :items-per-page="-1"
      hide-default-footer
      fixed-header
      style="height: 100%; overflow-y: auto"
    >
      <!-- Slot personalizado para la columna 'LT' -->
      <template v-slot:[`item.LT`]="{ item }">
        <v-btn
          v-if="item.LT !== totalLabel"
          title
          @click="goToInventory(item.LT)"
          class="lt-button"
        >
          {{ item.LT }}
        </v-btn>
        <span v-else class="lt-text">{{ item.LT }}</span>
      </template>

      <!-- Slot personalizado para la columna 'TOTAL' -->
      <template v-slot:[`item.TOTAL`]="{ item }">
        <span v-if="item.TOTAL !== undefined" class="total-text">{{
          item.TOTAL
        }}</span>
      </template>

      <!-- Botón para exportar a Excel -->
      <template v-slot:bottom>
        <v-btn color="success" dark @click="exportToExcel">
          <v-icon left>mdi-file-excel</v-icon>Exportar a Excel
        </v-btn>
      </template>
    </v-data-table>

    <v-alert v-if="error" type="error" dismissible>
      {{ error }}
    </v-alert>
  </v-card>
</template>

<script setup>
import router from "@/router";
import { computed } from "vue";
import { useLogiStore } from "../stores/LogiStore";
import { useDeviceStore } from "@/stores/deviceStore";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";

// Datos estáticos
const dispositivos = [
  {
    LT: "12516",
    Denominacion: "Interno secuencia cristal portón",
    CantTotal: 6,
  },
  { LT: "50039", Denominacion: "Finish calidad rechazo", CantTotal: 4 },
  {
    LT: "50091",
    Denominacion: "Interno secuencia portaequipajes izq",
    CantTotal: 37,
  },
  {
    LT: "50092",
    Denominacion: "Interno secuencia portaequipajes dch",
    CantTotal: 33,
  },
  { LT: "50104", Denominacion: "Finish volantes + Faros", CantTotal: 4 },
  {
    LT: "50105",
    Denominacion: "Interno peticiones adicionales porta izq",
    CantTotal: 6,
  },
  { LT: "50107", Denominacion: "Finish árboles transmisión", CantTotal: 3 },
  { LT: "50108", Denominacion: "Finish carriles", CantTotal: 4 },
  { LT: "50114", Denominacion: "Interno capote reciclable", CantTotal: 6 },
  {
    LT: "50123",
    Denominacion: "Interno secuencia pilotos traseros",
    CantTotal: 5,
  },
  {
    LT: "50124",
    Denominacion: "Interno secuencia pilotos traseros",
    CantTotal: 5,
  },
  {
    LT: "7684",
    Denominacion: "Interno secuencia cristal parabrisas",
    CantTotal: 7,
  },
  {
    LT: "50141",
    Denominacion: "Finish calidad rechazo suelos traseros",
    CantTotal: 1,
  },
  { LT: "50142", Denominacion: "Calidad rechazo portaequipajes", CantTotal: 2 },
  {
    LT: "50191",
    Denominacion: "Interno madera rechazo asientos finish",
    CantTotal: 4,
  },
  { LT: "50195", Denominacion: "Interno suministro KLTs CEVA", CantTotal: 2 },
  { LT: "50199", Denominacion: "Interno rechazo cristaleria", CantTotal: 2 },
  {
    LT: "50222",
    Denominacion: "Interno carro suministro embellecedor piloto",
    CantTotal: 2,
  },
  {
    LT: "50244",
    Denominacion: "Interno secuencia bolsa de accesorios",
    CantTotal: 4,
  },
  { LT: "50255", Denominacion: "Finish rechazo puertas", CantTotal: 3 },
  { LT: "50256", Denominacion: "Finish rechazo suelos", CantTotal: 2 },
  { LT: "50277", Denominacion: "Interno secuencia taloneras", CantTotal: 7 },
  {
    LT: "50299",
    Denominacion: "Interno secuencia GLT1 asiento china VS20",
    CantTotal: 10,
  },
  {
    LT: "50305",
    Denominacion: "Interno peticiones adicionales porta dcho",
    CantTotal: 2,
  },
  { LT: "50333", Denominacion: "INTERNO GLT2 MSLT", CantTotal: 2 },
  { LT: "50903", Denominacion: "GLT2 Calidad", CantTotal: 1 },
  {
    LT: "50978",
    Denominacion: "INTERNO suministro GLT2 reversible",
    CantTotal: 15,
  },
  { LT: "51001", Denominacion: "Interno secuencia tapa carril", CantTotal: 20 },
  {
    LT: "51005",
    Denominacion: "Interno secuencia trecho panorámico",
    CantTotal: 6,
  },
  {
    LT: "51008",
    Denominacion: "Interno secuencia cables principal",
    CantTotal: 9,
  },
  {
    LT: "51027",
    Denominacion: "Interno protector aletas recirculable izquier",
    CantTotal: 6,
  },
  {
    LT: "51028",
    Denominacion: "Interno protector aletas recirculable derecha",
    CantTotal: 6,
  },
  {
    LT: "51033",
    Denominacion: "Interno Recirculable protectores puerta corre",
    CantTotal: 5,
  },
  {
    LT: "51051",
    Denominacion: "Interno secuencia Rieles guía inferior",
    CantTotal: 5,
  },
  { LT: "51054", Denominacion: "Interno suministro KLT´s L1", CantTotal: 2 },
  {
    LT: "51061",
    Denominacion: "Interno secuencia tapa carril izquierda",
    CantTotal: 15,
  },
  {
    LT: "51067",
    Denominacion: "Carro interno recirculable varilla capot",
    CantTotal: 5,
  },
  {
    LT: "51088",
    Denominacion: "Interno recirculable caja izq. y soporte dch",
    CantTotal: 6,
  },
  {
    LT: "51097",
    Denominacion: "INTERNO secuencia cubierta frontal",
    CantTotal: 6,
  },
  {
    LT: "51099",
    Denominacion: "INTERNO suministro bandejas sirgas",
    CantTotal: 1,
  },
  {
    LT: "51166",
    Denominacion: "Interno secuencia cámara marcha atrás",
    CantTotal: 6,
  },
  {
    LT: "51222",
    Denominacion: "Interno secuencia carro de sirgas",
    CantTotal: 2,
  },
  { LT: "52015", Denominacion: "Interno secuencia BFW", CantTotal: 87 },
  { LT: "52021", Denominacion: "Interno secuencia juntas", CantTotal: 8 },
  { LT: "52024", Denominacion: "Interno secuencia juntas", CantTotal: 9 },
  {
    LT: "52026",
    Denominacion: "Interno secuencia cristales abatibles derecho",
    CantTotal: 5,
  },
  {
    LT: "52101",
    Denominacion: "INTERNO carro suministro IPARK MO",
    CantTotal: 3,
  },
  {
    LT: "52111",
    Denominacion: "Interno secuencia BFW instalación",
    CantTotal: 14,
  },
  {
    LT: "52122",
    Denominacion: "Interno carro comisionado megahalter",
    CantTotal: 7,
  },
  {
    LT: "52999",
    Denominacion: "Interno recirculable gancho freno",
    CantTotal: 6,
  },
  {
    LT: "53014",
    Denominacion: "Interno secuencia cristales laterales",
    CantTotal: 10,
  },
  {
    LT: "53021",
    Denominacion: "Interno secuencia caja asiento",
    CantTotal: 9,
  },
  {
    LT: "53025",
    Denominacion: "Interno secuencia cristales abatibles izquier",
    CantTotal: 5,
  },
  {
    LT: "53031",
    Denominacion: "Interno GLT1 12516 cristal portón",
    CantTotal: 5,
  },
  {
    LT: "53034",
    Denominacion: "Interno GLT1 1781 juntas para puertas",
    CantTotal: 9,
  },
  {
    LT: "53044",
    Denominacion: "Interno comisionado canales calefactores y pa",
    CantTotal: 4,
  },
  {
    LT: "53055",
    Denominacion: "Interno secuencia cristal trasero fijo",
    CantTotal: 6,
  },
  {
    LT: "53062",
    Denominacion: "Interno premontaje comisionado amplificador",
    CantTotal: 5,
  },
  { LT: "53063", Denominacion: "Interno secuencia Bombines", CantTotal: 6 },
  { LT: "53071", Denominacion: "Interno suministro Línea 3", CantTotal: 2 },
  {
    LT: "53098",
    Denominacion: "Interno secuencia plato giratorio Izqd.",
    CantTotal: 6,
  },
  {
    LT: "53099",
    Denominacion: "Interno secuencia plato giratorio Drch.",
    CantTotal: 6,
  },
  {
    LT: "53101",
    Denominacion: "Interno carro secuencia luna delantera Rusia",
    CantTotal: 5,
  },
  { LT: "53111", Denominacion: "Interno Suministro KLT´s DRT", CantTotal: 1 },
  { LT: "53122", Denominacion: "INTERNO recirculable Windy", CantTotal: 5 },
  { LT: "53281", Denominacion: "Interno secuencia cintos", CantTotal: 4 },
  {
    LT: "53801",
    Denominacion: "Interno secuencia cristal lateral",
    CantTotal: 7,
  },
  {
    LT: "53911",
    Denominacion: "Interno carro secuencia baterías",
    CantTotal: 8,
  },
  {
    LT: "53912",
    Denominacion: "Interno cont secuencia baterías",
    CantTotal: 5,
  },
  { LT: "53999", Denominacion: "Interno secuencia arañas", CantTotal: 7 },
  {
    LT: "54035",
    Denominacion: "Interno secuencia consola central",
    CantTotal: 5,
  },
  {
    LT: "54099",
    Denominacion: "Carro interno recirculable peldaños delantero",
    CantTotal: 7,
  },
  { LT: "54111", Denominacion: "Interno secuencia carriles", CantTotal: 27 },
  {
    LT: "55016",
    Denominacion: "Interno secuencia pared separadora poste B",
    CantTotal: 5,
  },
  {
    LT: "55017",
    Denominacion: "Interno secuencia pared separadora",
    CantTotal: 22,
  },
  {
    LT: "55022",
    Denominacion: "Interno secuencia tack ventil VS20",
    CantTotal: 6,
  },
  {
    LT: "55025",
    Denominacion: "Interno secuencia cajas asiento westfalia",
    CantTotal: 8,
  },
  {
    LT: "55069",
    Denominacion: "Interno secuencia prolongación cama",
    CantTotal: 12,
  },
  { LT: "55077", Denominacion: "Interno secuencia DBEs", CantTotal: 6 },
  {
    LT: "56008",
    Denominacion: "Interno secuencia depósito combustible",
    CantTotal: 30,
  },
  { LT: "56009", Denominacion: "Interno secuencia muelles", CantTotal: 12 },
  {
    LT: "56015",
    Denominacion: "Interno comisionado chapa anticalorica escape",
    CantTotal: 5,
  },
  {
    LT: "56018",
    Denominacion: "Interno secuencia deposito agua L6",
    CantTotal: 4,
  },
  {
    LT: "56021",
    Denominacion: "Interno secuencia gancho remolque",
    CantTotal: 6,
  },
  {
    LT: "56022",
    Denominacion: "Interno comisionado chapa anticalorica",
    CantTotal: 5,
  },
  {
    LT: "56046",
    Denominacion: "Interno secuencia calefacción H12",
    CantTotal: 7,
  },
  {
    LT: "56088",
    Denominacion: "Interno premontaje comisionado HZ7 tuberías A",
    CantTotal: 6,
  },
  {
    LT: "56101",
    Denominacion: "Interno carro comisionado tuberías dobladora",
    CantTotal: 3,
  },
  {
    LT: "56308",
    Denominacion: "Interno secuencia depósito combustible LINEA",
    CantTotal: 7,
  },
  {
    LT: "56906",
    Denominacion: "Interno suministro comisionado husillo",
    CantTotal: 5,
  },
  {
    LT: "56956",
    Denominacion: "Interno secuencia cubierta compartimiento izq",
    CantTotal: 5,
  },
  {
    LT: "57058",
    Denominacion: "Interno comisionado hot end OM654",
    CantTotal: 5,
  },
  {
    LT: "57059",
    Denominacion: "Interno secuencia cajas de cambio",
    CantTotal: 9,
  },
  {
    LT: "57064",
    Denominacion: "Interno secuencia tubos escape delanteros",
    CantTotal: 11,
  },
  {
    LT: "57065",
    Denominacion: "Interno secuencia tubos escape medios",
    CantTotal: 9,
  },
  {
    LT: "57066",
    Denominacion: "Interno secuencia tubos escape traseros",
    CantTotal: 8,
  },
  {
    LT: "57067",
    Denominacion: "Interno secuencia árboles transmisión",
    CantTotal: 35,
  },
  { LT: "57073", Denominacion: "Interno secuencia alternadores", CantTotal: 6 },
  {
    LT: "57074",
    Denominacion: "Interno secuencia soporte cajas de cambio",
    CantTotal: 5,
  },
  { LT: "57075", Denominacion: "Interno secuencia compresores", CantTotal: 5 },
  { LT: "57078", Denominacion: "Interno secuencia palieres", CantTotal: 6 },
  { LT: "57099", Denominacion: "Interno secuencia arrancadores", CantTotal: 4 },
  {
    LT: "57107",
    Denominacion: "Interno secuencia tuberías aire acondicionado",
    CantTotal: 5,
  },
  { LT: "57111", Denominacion: "Interno secuencia ventiladores", CantTotal: 6 },
  { LT: "57702", Denominacion: "INTERNO motor M254", CantTotal: 300 },
  {
    LT: "58063",
    Denominacion: "Interno secuencia Bombín L8 VS20",
    CantTotal: 6,
  },
  {
    LT: "58101",
    Denominacion: "Interno secuencia cristal puerta del.dcha",
    CantTotal: 5,
  },
  {
    LT: "58102",
    Denominacion: "Interno secuencia cristal puerta del.izq",
    CantTotal: 5,
  },
  {
    LT: "58111",
    Denominacion: "Interno secuencia cuerpo rodadura",
    CantTotal: 5,
  },
  {
    LT: "58112",
    Denominacion: "INTERNO SECUENCA CUERPO RODADURA IZQUIERA",
    CantTotal: 5,
  },
  {
    LT: "59036",
    Denominacion: "Interno secuencia limpiaparabrisas",
    CantTotal: 5,
  },
  {
    LT: "59037",
    Denominacion: "Interno relleno revestimiento paso rueda dere",
    CantTotal: 5,
  },
  {
    LT: "59038",
    Denominacion: "Interno relleno revestimiento paso rueda izqu",
    CantTotal: 5,
  },
  {
    LT: "59081",
    Denominacion: "Interno secuencia capsula insonorización",
    CantTotal: 5,
  },
  { LT: "59082", Denominacion: "INTERNO GLT1 faros", CantTotal: 5 },
  {
    LT: "59083",
    Denominacion: "INTERNO carro de acopio secuencia cajas de ag",
    CantTotal: 3,
  },
  { LT: "59087", Denominacion: "Interno protector frontal", CantTotal: 6 },
  { LT: "59088", Denominacion: "Interno secuencia faros", CantTotal: 70 },
  { LT: "59089", Denominacion: "Interno secuencia caja agua", CantTotal: 24 },
  {
    LT: "59099",
    Denominacion: "Interno prem. Comis. Sop. Cerr. Capot",
    CantTotal: 6,
  },
  { LT: "59112", Denominacion: "Interno secuencia volante", CantTotal: 33 },
  {
    LT: "51122",
    Denominacion:
      "Interno secuencia manta hueco motor+comisionado insonorización Torreta",
    CantTotal: 1,
  },
  {
    LT: "51901",
    Denominacion: "Interno secuencia junta doble puerta",
    CantTotal: 1,
  },
  {
    LT: "54113",
    Denominacion: "Interno transporte secuencia carriles",
    CantTotal: 1,
  },
  { LT: "61008", Denominacion: "Interno secuencia cables", CantTotal: 1 },
  { LT: "61009", Denominacion: "Interno secuencia GLT1 cables", CantTotal: 1 },
  {
    LT: "63012",
    Denominacion: "Interno secuencia cristales delanteros Izq",
    CantTotal: 1,
  },
  { LT: "65599", Denominacion: "Interno secuencia Thermomodul", CantTotal: 1 },
  { LT: "66011", Denominacion: "Interno secuencia DC-Booster", CantTotal: 1 },
  {
    LT: "63001",
    Denominacion: "Interno secuencia lunas VS20/VAN ES C/VAN EA P",
    CantTotal: 1,
  },
  {
    LT: "63011",
    Denominacion: "Interno Secuencia cristal portón y doble puerta",
    CantTotal: 1,
  },
  {
    LT: "68015",
    Denominacion: "Interno secuencia cristal puerta corredera Izq",
    CantTotal: 1,
  },
  {
    LT: "68016",
    Denominacion: "Interno secuencia cristal puerta corredera Drch",
    CantTotal: 1,
  },
  {
    LT: "63801",
    Denominacion: "Interno secuencia cristal trasero Izq",
    CantTotal: 1,
  },
  {
    LT: "63802",
    Denominacion: "Interno secuencia cristal trasero Drch",
    CantTotal: 1,
  },
  {
    LT: "62111",
    Denominacion: "Interno secuencia BFW instalación (52111)",
    CantTotal: 1,
  },
  {
    LT: "62112",
    Denominacion: "Interno secuencia BFW secado (52015)",
    CantTotal: 1,
  },
  {
    LT: "62113",
    Denominacion: "Interno secuencia BFW recambios VAN EA",
    CantTotal: 1,
  },
  {
    LT: "64063",
    Denominacion: "Interno secuencia techo panorámico VAN EA",
    CantTotal: 1,
  },
  {
    LT: "67111",
    Denominacion: "Interno secuencia motor delantero EATS VAN EA",
    CantTotal: 1,
  },
];

// Lista de ubicaciones (agrega tu lista actual de ubicaciones)
const ubicaciones = [
  "Axia Grupo / Los llanos",
  "Axia Grupo / Paduleta",
  "DHL Supply Chain / Buffer 1ª necesidad",
  "DHL Supply Chain / Nave secuencia",
  "DHL Supply Chain / Sector 86",
  "Mercedes-Benz Vitoria / Montaje bruto",
  "Mercedes-Benz Vitoria / Montaje final",
  "Sin ubicación",
];

// Computed para generar los datos de la tabla
const logiStore = useLogiStore();
const tableData = computed(() => {
  return dispositivos.map((dispositivo) => {
    const familyData = logiStore.getDataByFamily(dispositivo.LT);
    const total = logiStore.getTotalByFamily(dispositivo.LT);
    return {
      LT: dispositivo.LT,
      Denominacion: dispositivo.Denominacion,
      CantTotal: dispositivo.CantTotal,
      ...ubicaciones.reduce((acc, ubicacion) => {
        acc[ubicacion] = familyData[ubicacion] || 0;
        return acc;
      }, {}),
      TOTAL: total,
    };
  });
});

// Agregar fila extra para mostrar el conteo de familias y sumas de dispositivos
const totalLabel = "Cantidad de Familias: " + dispositivos.length;
const extendedTableData = computed(() => {
  const originalData = tableData.value;
  const totals = {
    LT: totalLabel,
    Denominacion: "",
    CantTotal: "",
  };

  ubicaciones.forEach((ubicacion) => {
    totals[ubicacion] = originalData.reduce(
      (sum, item) => sum + (item[ubicacion] || 0),
      0
    );
  });

  totals.TOTAL = originalData.reduce((sum, item) => sum + (item.TOTAL || 0), 0);

  return [...originalData, totals];
});

// Headers con colores para exportación a Excel
const displayHeaders = computed(() => {
  return [
    { title: "LT", value: "LT", color: "FF0070C0" }, // Azul
    { title: "Denominación", value: "Denominacion", color: "FF0070C0" }, // Azul
    { title: "Cantidad total", value: "CantTotal", color: "FFFFFF00" }, // Amarillo
    {
      title: "Axia Grupo / Los llanos",
      value: "Axia Grupo / Los llanos",
      color: "FF00B050",
    },
    {
      title: "Axia Grupo / Paduleta",
      value: "Axia Grupo / Paduleta",
      color: "FF00B050",
    },
    {
      title: "DHL Supply Chain / Buffer 1ª necesidad",
      value: "DHL Supply Chain / Buffer 1ª necesidad",
      color: "FF7F7F7F",
    },
    {
      title: "DHL Supply Chain / Nave secuencia",
      value: "DHL Supply Chain / Nave secuencia",
      color: "FF7F7F7F",
    },
    {
      title: "DHL Supply Chain / Sector 86",
      value: "DHL Supply Chain / Sector 86",
      color: "FF7F7F7F",
    },
    {
      title: "Mercedes-Benz Vitoria / Montaje final",
      value: "Mercedes-Benz Vitoria / Montaje final",
      color: "FFFF6D04",
    },
    { title: "Sin ubicación", value: "Sin ubicación", color: "FF00B0F0" },
    { title: "TOTAL", value: "TOTAL", color: "FF000000" }, // Negro
  ];
});

// Acceder al estado de carga y errores desde el store
const isLoading = computed(() => logiStore.isLoading);
const error = computed(() => logiStore.error);

// Función para exportar la tabla a Excel usando ExcelJS y FileSaver
const exportToExcel = async () => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Tablero_Montaje_Final");

  // Añadir encabezados a la hoja de Excel
  const headerRow = worksheet.addRow(
    displayHeaders.value.map((header) => header.title)
  );

  // Establecer altura fija para la fila de encabezados
  worksheet.getRow(1).height = 30; // Aproximadamente 50 píxeles

  // Aplicar estilos a los encabezados
  headerRow.eachCell((cell, colNumber) => {
    const header = displayHeaders.value[colNumber - 1];
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: header.color },
    };

    // Establecer el color de la fuente
    if (header.title === "Cantidad total") {
      cell.font = { bold: true, color: { argb: "FFFF0000" } }; // Rojo
    } else {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } }; // Blanco
    }

    cell.alignment = { vertical: "middle", horizontal: "center" };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  // Añadir filas de datos
  extendedTableData.value.forEach((row, rowIndex) => {
    const rowData = displayHeaders.value.map((header) => row[header.value]);
    const excelRow = worksheet.addRow(rowData);

    excelRow.eachCell((cell, colIndex) => {
      const isTotalRow = rowIndex === extendedTableData.value.length - 1;
      const isFirstColumn = colIndex === 1;
      const isSecondOrThirdColumn = colIndex === 2 || colIndex === 3;
      const isLastColumn = colIndex === displayHeaders.value.length;

      if (isTotalRow) {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" } }; // Blanco y negrita
        if (isFirstColumn || isSecondOrThirdColumn) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF000000" },
          }; // Negro
        } else if (isLastColumn) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFA9A9A9" },
          }; // Gris más oscuro
        } else {
          cell.font = { bold: true, color: { argb: "FF000000" } };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFD3D3D3" },
          }; // Gris claro
        }
      } else {
        if (isFirstColumn) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF0000" },
          }; // Rojo
          cell.font = { bold: true, color: { argb: "FFFFFFFF" } }; // Blanco y negrita
        } else if (isSecondOrThirdColumn) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFC5D9F1" },
          }; // Azul cielo
          // Aplicar bordes a columnas 2 y 3, excluyendo primera y última fila
          if (
            //rowIndex !== 0 &&
            rowIndex !==
            extendedTableData.value.length - 1
          ) {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        } else if (isLastColumn) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFD3D3D3" },
          }; // Gris claro
          cell.font = { bold: true };
        }
      }

      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
  });

  // Ajustar el ancho de las columnas automáticamente
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const columnLength = cell.value ? cell.value.toString().length : 10;
      if (columnLength > maxLength) {
        maxLength = columnLength;
      }
    });
    column.width = maxLength < 10 ? 10 : maxLength + 2;
  });

  // Generar el buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Crear un Blob y descargarlo usando FileSaver
  const blob = new Blob([buffer], { type: "application/octet-stream" });
  saveAs(blob, "Tablero_Montaje_Final.xlsx");
};

// Acceder al store
const deviceStore = useDeviceStore();

// Función para redirigir a la vista de inventario con la familia seleccionada
const goToInventory = (familia) => {
  deviceStore.setFiltroFamily([familia]);
  router.push({ name: "Inventory" });
};
</script>

<style scoped>
/* Estilos para las celdas de datos */
.data-cell {
  padding: 0 !important;
}

.cell-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 8px;
}

/* Estilos específicos para la columna 'LT' */
.lt-button {
  color: inherit; /* Hereda el color del padre */
}

/* Aplicar bordes solo a columnas 2 y 3, excluyendo primera y última fila */
.v-data-table__td:nth-child(2),
.v-data-table__td:nth-child(3) {
  border: 1px solid #000 !important; /* Bordes para columnas 2 y 3 */
}
</style>
