<!-- TableView.vue -->

<template>
  <v-card
    v-if="isAuthenticated"
    class="d-flex flex-column"
    style="height: calc(100vh - 80px); overflow: hidden"
  >
    <!-- Barra de progreso -->
    <v-progress-linear
      v-if="tableStore.loading"
      indeterminate
      color="primary"
    ></v-progress-linear>

    <!-- Tabla de datos -->
    <v-data-table
      v-else
      :headers="dynamicHeaders"
      :items="tableStore.data"
      class="elevation-1"
      :items-per-page="tableStore.perPage"
      :page="tableStore.tablePage"
      v-model:items-per-page="tableStore.perPage"
      :server-items-length="tableStore.total"
      hide-default-footer
      fixed-header
    >
      <!-- Footer personalizado similar al de HomeView -->
      <template #bottom>
        <!-- Layout pantallas pequeñas: dos filas -->
        <div class="d-flex d-md-none flex-column w-100 pa-2">
          <!-- Primera fila: Selector y Paginación -->
          <v-row class="w-100 d-flex align-center justify-start mb-2 flex-wrap">
            <v-col cols="12" class="mb-2">
              <v-select
                v-model="tableStore.perPage"
                :items="itemsPerPageOptions"
                label="Filas por página"
                hide-details
                dense
                outlined
                style="max-width: 150px"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-pagination
                v-model="tableStore.actualPage"
                :length="tableStore.totalPages"
                total-visible="2"
              ></v-pagination>
            </v-col>
          </v-row>

          <!-- Segunda fila: Total a la izquierda, Botón a la derecha -->
          <v-row class="w-100 d-flex align-center justify-space-between">
            <v-col cols="auto" class="d-flex align-center">
              <span>Total: {{ tableStore.total }}</span>
            </v-col>
            <v-col cols="auto" class="d-flex justify-end">
              <v-btn
                color="success"
                dark
                @click="exportarExcel"
                small
                aria-label="Exportar a Excel"
              >
                <v-icon left>mdi-file-excel</v-icon>
                Exportar a Excel
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <!-- Layout pantallas grandes: todo en una sola fila -->
        <div
          class="d-none d-md-flex w-100 pa-2 align-center justify-space-between flex-wrap"
        >
          <div class="d-flex align-center flex-wrap">
            <v-select
              v-model="tableStore.perPage"
              :items="itemsPerPageOptions"
              label="Filas por página"
              hide-details
              dense
              outlined
              style="max-width: 150px"
              class="mr-4"
            ></v-select>

            <v-pagination
              v-model="tableStore.actualPage"
              :length="tableStore.totalPages"
              total-visible="2"
              class="mr-4"
            ></v-pagination>

            <span>Total: {{ tableStore.total }}</span>
          </div>

          <div class="d-flex">
            <v-btn
              color="success"
              dark
              @click="exportarExcel"
              small
              aria-label="Exportar a Excel"
            >
              <v-icon left>mdi-file-excel</v-icon>
              Exportar a Excel
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>

    <v-alert v-if="tableStore.error" type="error" dismissible>
      {{ tableStore.error }}
    </v-alert>
  </v-card>
</template>

<script setup>
import { onMounted, computed, watch } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useTableStore } from "@/stores/tableStore";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

// Importar y usar los stores
const authStore = useAuthStore();
const tableStore = useTableStore();

// Estado de autenticación
const isAuthenticated = computed(() => authStore.isAuthenticated);

// Definir los encabezados base
const baseHeaders = [
  { title: "Dispositivo", value: "Dispositivo", sortable: true },
  { title: "Lector", value: "Lector", sortable: true },
  { title: "Timestamp", value: "Time", sortable: true },
  { title: "Variable", value: "Variable", sortable: true },
  { title: "Roll", value: "Roll", sortable: true },
  { title: "Pitch", value: "Pitch", sortable: true },
];

// Definir los encabezados dinámicos
const dynamicHeaders = computed(() => {
  const additionalKeys = new Set();

  tableStore.data.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (!baseHeaders.find((header) => header.value === key)) {
        additionalKeys.add(key);
      }
    });
  });

  const additionalHeaders = Array.from(additionalKeys).map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1),
    value: key,
    sortable: true,
  }));

  return [...baseHeaders, ...additionalHeaders];
});

// Opciones para filas por página
const itemsPerPageOptions = [
  { title: "10", value: 10 },
  { title: "20", value: 20 },
  { title: "50", value: 50 },
  { title: "100", value: 100 },
  { title: "500", value: 500 },
  { title: "1000", value: 1000 },
];

// Watch para cambios en la página real y en itemsPerPage
watch(
  () => [tableStore.actualPage, tableStore.perPage],
  ([newPage, newPerPage]) => {
    tableStore.fetchTableData(newPage, newPerPage);
  },
  { immediate: true }
);

// Método para exportar a Excel
const exportarExcel = async () => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Eventos");

  // Definir las columnas en Excel a partir de dynamicHeaders
  worksheet.columns = dynamicHeaders.value.map((header) => ({
    header: header.title,
    key: header.value,
    width: 20,
  }));

  // Añadir filas de datos
  tableStore.data.forEach((row) => {
    const rowData = {};
    dynamicHeaders.value.forEach((h) => {
      rowData[h.value] = row[h.value];
    });
    worksheet.addRow(rowData);
  });

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), "eventos.xlsx");
};

onMounted(async () => {
  if (isAuthenticated.value) {
    await tableStore.fetchTableData(tableStore.actualPage, tableStore.perPage);
  }
  console.log("Datos del Store:", tableStore.data);
});
</script>

<style>
.elevation-1 {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
}

/* Ajustar el padding en la fila del pie de página */
.pa-2 {
  padding: 0.5rem;
}

/* Estilos responsivos para el footer */
@media (max-width: 600px) {
  .text-right {
    text-align: center !important;
  }

  .mr-4 {
    margin-right: 0 !important;
    margin-bottom: 0.5rem !important;
  }
}

.v-data-table {
  overflow: auto;
}
</style>
