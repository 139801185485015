const baseURL = process.env.VUE_APP_API_URL;

export default {
  async getAllDevices() {
    const url = `${baseURL}/api/devices`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Error en la petición: ${response.statusText}`);
    }
    const data = await response.json();
    return data.data; // Accedemos al arreglo de dispositivos
  },

  async getFamilies() {
    const url = `${baseURL}/api/families`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Error en la petición: ${response.statusText}`);
    }
    const data = await response.json();
    return data; // Accedemos al arreglo de dispositivos
  },

  async getDevicesInBounds(minLat, minLon, maxLat, maxLon) {
    const url = `${baseURL}/api/devices_in_bounds?minlat=${minLat}&minlon=${minLon}&maxlat=${maxLat}&maxlon=${maxLon}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Error en la petición: ${response.statusText}`);
    }
    const data = await response.json();
    return data.data; // Accedemos al arreglo de dispositivos
  },

  async getConteoDispositivos() {
    const url = `${baseURL}/api/contar_dispositivos`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Error en la petición: ${response.statusText}`);
    }
    const data = await response.json();
    return data; // Accedemos al arreglo de dispositivos
  },

  async login(username, password) {
    const url = `${baseURL}/api/login`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`Error en la petición: ${response.statusText}`);
      }

      const status = response.status;
      const data = await response.json();
      return { status, data }; // Por ejemplo, `data` podría contener un token o un mensaje
    } catch (error) {
      console.error("Error al intentar iniciar sesión:", error);
      throw error;
    }
  },

  async validateToken() {
    const url = `${baseURL}/api/validate_token`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    return response;
  },

  async logout() {
    const url = `${baseURL}/api/logout`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(`Error en la petición: ${response.statusText}`);
    }

    return response;
  },

  /**
   * Obtiene los datos de la tabla con paginación.
   * @param {number} page - Número de página (opcional, por defecto 1).
   * @param {number} perPage - Número de registros por página (opcional, por defecto 100).
   * @returns {Object} - Objeto que contiene los datos de la tabla y metadatos de paginación.
   */
  async getTableData(page, perPage) {
    const url = new URL(`${baseURL}/api/get_table_data`);
    url.searchParams.append("page", page);
    url.searchParams.append("per_page", perPage);

    const response = await fetch(url.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(
        `Error en la petición: ${response.status} - ${errorMessage}`
      );
    }

    const data = await response.json();
    return data; // Retorna todo el objeto que incluye data, total, page, per_page y total_pages
  },

  async getManuales() {
    const url = `${baseURL}/api/manuales`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(
        `Error al obtener el menú de manuales: ${response.statusText}`
      );
    }
    return await response.json();
  },

  async getPdfUrl(docId) {
    const url = `${baseURL}/api/manuales/pdf/${encodeURIComponent(docId)}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(
        `Error al obtener la URL del PDF: ${response.statusText}`
      );
    }
    return await response.json();
  },

  async deleteDevices(deviceIds) {
    const url = `${baseURL}/api/delete_devices`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ ids: deviceIds }),
        credentials: "include",
      });

      // Aquí movemos la declaración de `data` para que esté disponible en todo el bloque.
      const data = await response.json();

      if (!response.ok) {
        // Verificar si el código de estado es 403
        if (response.status === 403) {
          throw {
            status: 403,
            message:
              data.message ||
              "Acceso denegado: no tienes permisos de administrador.",
          };
        }
        throw {
          status: response.status,
          message: data.message || response.statusText,
        };
      }

      return data; // Aquí regresamos el resultado de la eliminación
    } catch (error) {
      console.error("Error al intentar eliminar dispositivos:", error);
      throw error;
    }
  },
  async getPocStatus() {
    const url = `${baseURL}/api/device_poc_status`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Error en la petición: ${response.statusText}`);
    }
    const data = await response.json();
    return data.data; // Devuelve los datos procesados
  },
  async actualizarUsuario(datosUsuario) {
    try {
      const response = await fetch(`${baseURL}/api/update_user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        credentials: "include",
        body: JSON.stringify(datosUsuario),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Error al actualizar el usuario");
      }

      return data.message;
    } catch (error) {
      console.error("Error en la actualización:", error);
      throw error;
    }
  },
};
