// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import InventoryView from "@/views/InventoryView.vue";
import StatusView from "@/views/StatusView.vue";
import GraphicsView from "@/views/GraphicsView.vue";
import SpecialView from "@/views/SpecialView.vue";
import LogisticsView from "@/views/LogisticsView.vue";
import LoginView from "@/views/LoginView.vue";
import ControlView from "@/views/ControlView.vue";
import TableView from "@/views/TableView.vue";
import { useAuthStore } from "@/stores/authStore";
import ManualesView from "@/views/ManualesView.vue";
import PocStatusView from "@/views/PocStatusView.vue";
import ProfileUpdate from "@/views/ProfileUpdate.vue";
// Importa más vistas si las tienes

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: InventoryView,
    meta: { requiresAuth: true },
  },
  {
    path: "/status",
    name: "Status",
    component: StatusView,
    meta: { requiresAuth: true },
  },
  {
    path: "/graphics",
    name: "Graphics",
    component: GraphicsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/special",
    name: "Special",
    component: SpecialView,
    meta: { requiresAuth: true },
  },
  {
    path: "/Logistics",
    name: "Logistics",
    component: LogisticsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/Control",
    name: "Control",
    component: ControlView,
    meta: { requiresAuth: true },
  },
  {
    path: "/NewData",
    name: "NewData",
    component: TableView,
    meta: { requiresAuth: true },
  },
  {
    path: "/Manuales",
    name: "Manuales",
    component: ManualesView,
    meta: { requiresAuth: false },
  },
  {
    path: "/poc-status",
    name: "PocStatus",
    component: PocStatusView,
    meta: { requiresAuth: true },
  },
  {
    path: "/perfil",
    name: "Perfil",
    component: ProfileUpdate,
    meta: { requiresAuth: true },
  },
  // Añade más rutas aquí
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  // Comprobar si la ruta requiere autenticación
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Verificar si el token de la cookie está presente
    authStore.checkAuthentication();

    if (!authStore.isAuthenticated) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
