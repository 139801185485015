<!-- src/views/StatusView.vue -->
<template>
  <v-card
    v-if="isAuthenticated"
    class="d-flex flex-column"
    style="height: calc(100vh - 80px); overflow: hidden"
  >
    <!-- Indicador de carga -->
    <v-progress-linear
      :active="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>

    <!-- Título de la tarjeta y filtros -->
    <v-card-title v-if="!loading">
      <v-row class="w-100" no-gutters>
        <!-- Selector de familia -->
        <v-col cols="12" md="4">
          <v-select
            v-model="selectedFamily"
            :items="familyOptions"
            label="Seleccionar Familia"
            item-title="title"
            item-value="value"
            clearable
            dense
            outlined
            @update:model-value="onFamilyChange"
            class="mr-2"
          ></v-select>
        </v-col>

        <!-- Campo de búsqueda por ubicación -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="filterLocation"
            label="Filtrar por Localización"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>

        <!-- Indicador de Totales -->
        <v-col cols="12" md="4" class="d-flex align-center justify-end">
          <v-card class="pa-2" elevation="2" outlined>
            <v-card-title class="text-h6">
              Total dispositivos: <strong>{{ totalDevicesFamily }}</strong>
            </v-card-title>
            <v-card-title class="text-h6">
              Total ubicaciones: <strong>{{ totalLocations }}</strong>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-card-title>

    <!-- Tabla con encabezados fijos y altura ajustable -->
    <v-data-table
      v-if="!loading && selectedFamily"
      :headers="headers"
      :items="filteredData"
      v-model:page="page"
      v-model:items-per-page="itemsPerPage"
      class="elevation-1 flex-grow-1"
      fixed-header
      hide-default-footer
      style="overflow: hidden"
    >
      <!-- Slot personalizado para el pie de página -->
      <template #bottom>
        <v-row align="center" justify="space-between" class="w-100 pa-2">
          <!-- Selector de Filas por Página y Paginación -->
          <v-col cols="12" sm="8" class="d-flex align-center">
            <v-select
              v-model="itemsPerPage"
              :items="itemsPerPageOptions"
              item-title="title"
              item-value="value"
              label="Filas por página"
              hide-details
              dense
              outlined
              class="mr-4"
              style="max-width: 150px"
            ></v-select>

            <v-pagination
              v-model="page"
              :length="pageCount"
              total-visible="7"
            ></v-pagination>
          </v-col>

          <!-- Botón de Exportar a Excel -->
          <v-col cols="12" sm="4" class="text-right">
            <v-btn
              color="success"
              dark
              @click="exportarExcel"
              class="mt-2 mt-sm-0"
              small
              aria-label="Exportar a Excel"
            >
              <v-icon left>mdi-file-excel</v-icon>
              Exportar a Excel
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useDeviceStore } from "@/stores/deviceStore";
import { useAuthStore } from "@/stores/authStore";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default {
  name: "StatusView",
  setup() {
    const deviceStore = useDeviceStore();
    deviceStore.setFiltroName("");
    deviceStore.setFiltroReader("");
    deviceStore.setFiltroLocation("");
    deviceStore.setFiltroDevicetype(null);
    const authStore = useAuthStore();
    const isAuthenticated = ref(authStore.isAuthenticated);

    const loading = ref(true);

    const selectedFamily = ref(deviceStore.filtroFamily);

    const filterLocation = computed({
      get: () => deviceStore.filtroLocation,
      set: (value) => {
        deviceStore.filtroLocation = value;
      },
    });

    const page = ref(1);
    const itemsPerPage = ref(10);

    const pageCount = computed(() => {
      if (itemsPerPage.value === -1) {
        return 1;
      }
      return Math.ceil(filteredData.value.length / itemsPerPage.value);
    });

    const itemsPerPageOptions = [
      { title: "10", value: 10 },
      { title: "20", value: 20 },
      { title: "50", value: 50 },
      { title: "100", value: 100 },
      { title: "500", value: 500 },
      { title: "1000", value: 1000 },
      { title: "Todas", value: -1 },
    ];

    const familyOptions = computed(() => {
      return deviceStore.availableFamilies
        .slice() // Creamos una copia del array
        .sort((a, b) => a.localeCompare(b)) // Ordenar alfabéticamente de forma ascendente
        .map((family) => ({
          title: family,
          value: family,
        }));
    });

    const filteredData = computed(() => {
      const data = [];
      const devicesCountByLocation = deviceStore.devicesCountByLocation;

      Object.entries(devicesCountByLocation).forEach(([location, value]) => {
        if (
          filterLocation.value &&
          !location.toLowerCase().includes(filterLocation.value.toLowerCase())
        ) {
          return;
        }
        const { count, reader } = value;
        data.push({ location, reader, count });
      });

      return data;
    });

    const totalDevicesFamily = computed(() => {
      return filteredData.value.reduce((total, item) => total + item.count, 0);
    });

    const totalLocations = computed(() => {
      return filteredData.value.length;
    });

    watch([itemsPerPage.value, filterLocation, selectedFamily], () => {
      page.value = 1;
    });

    const onFamilyChange = (family) => {
      deviceStore.setFiltroFamily(family);
      page.value = 1;
    };

    const exportarExcel = async () => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Estado");

      worksheet.columns = headers.map((header) => ({
        header: header.title,
        key: header.key,
        width: 25,
      }));

      filteredData.value.forEach((dato) => {
        worksheet.addRow({
          location: dato.location,
          reader: dato.reader,
          count: dato.count,
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), "estado.xlsx");
    };

    const headers = [
      { title: "Localización", key: "location", sortable: true },
      { title: "Reader", key: "reader", sortable: true },
      { title: "Cantidad de Dispositivos", key: "count", sortable: true },
    ];

    onMounted(async () => {
      if (deviceStore.devices.length === 0) {
        loading.value = true;
        await deviceStore.fetchDevices();
        loading.value = false;
      } else {
        loading.value = false;
      }
    });

    return {
      selectedFamily,
      onFamilyChange,
      familyOptions,
      filterLocation,
      headers,
      itemsPerPageOptions,
      page,
      itemsPerPage,
      loading,
      pageCount,
      totalLocations,
      totalDevicesFamily,
      exportarExcel,
      isAuthenticated,
      filteredData,
    };
  },
};
</script>
